<script setup>
import { ref } from 'vue';
import DatePicker from 'primevue/datepicker';
import moment from 'moment';

const props = defineProps({
    modelValue: {
        type: [String, Date, null],
        required: false,
        default: null,
    },
    minDate: {
        type: [null, Object, Date],
        default: moment(),
        required: false,
    },
    placeholder: {
        type: String,
        default: 'Select a date',
    },
    showTime: {
        type: Boolean,
        default: false,
    },
    timeOnly: {
        type: Boolean,
        default: false,
    },
    placeholderAddDays: {
        type: Number,
        default: 1,
    },
    noMinDate: {
        type: Boolean,
        default: false,
    },
});

const selectedDate = ref(
    props.modelValue
        ? moment(props.modelValue).format('MMMM DD, YYYY')
        : moment().add(props.placeholderAddDays, 'd').format('MMMM DD, YYYY'),
);

const emit = defineEmits(['update:modelValue']);

const handleUpdate = ($event) => {
    selectedDate.value = moment($event).toDate();
    emit('update:modelValue', $event);
};
</script>

<template>
    <DatePicker
        :showTime="showTime"
        :step-minute="5"
        :timeOnly="timeOnly"
        :select-other-months="true"
        hourFormat="12"
        fluid
        :placeholder="placeholder"
        :model-value="selectedDate"
        :minDate="noMinDate ? null : minDate.toDate()"
        @update:model-value="($event) => handleUpdate($event)"
        dateFormat="MM dd, yy"
    />
</template>
